export const questions = [
  {
    question: "كي تقوم الصباح شنية تعمل ؟",
    answers: [
      require("../assets/questions/Q1A1.png"),
      require("../assets/questions/Q1A2.png"),
      require("../assets/questions/Q1A3.png"),
      require("../assets/questions/Q1A4.png"),
    ],
  },
  {
    question: "في القهوة شنوة تلعب ؟",
    answers: [
      require("../assets/questions/Q2A1.png"),
      require("../assets/questions/Q2A2.png"),
      require("../assets/questions/Q2A3.png"),
      require("../assets/questions/Q2A4.png"),
    ],
  },
  {
    question: "مروح لدار وحصروك كلاب الحومة شنوا ردة فعلك",
    answers: [
      require("../assets/questions/Q3A1.png"),
      require("../assets/questions/Q3A2.png"),
      require("../assets/questions/Q3A3.png"),
      require("../assets/questions/Q3A4.png"),
    ],
  },
  {
    question: "كي كنت صغير شنية كنت تلعب...",
    answers: [
      require("../assets/questions/Q4A1.png"),
      require("../assets/questions/Q4A2.png"),
      require("../assets/questions/Q4A3.png"),
      require("../assets/questions/Q4A4.png"),
    ],
  },
  {
    question: "عرضتك عركة في شارع شتعمل ؟",
    answers: [
      require("../assets/questions/Q5A1.png"),
      require("../assets/questions/Q5A2.png"),
      require("../assets/questions/Q5A3.png"),
      require("../assets/questions/Q5A4.png"),
    ],
  },
];

export const results = [
  {
    title: "PIVOT",
    text: " إنت تحب روحك ديمة في قلب الحدث ديمة تتحرك من بلاصة لبلاصة وين إحطوك تلقى روحك",
    img: require("../assets/results/R1.png"),
  },
  {
    title: "DEFENSEUR",
    text: "إنت صاحب مواقف وإلي عندك تقولوا وديمة واضح مع الناس لكل بلاصتك في قلب الدفاع ",

    img: require("../assets/results/R2.png"),
  },
  {
    title: "GOAL",

    text: "ما تحبش تظهر روحك بين لعباد أما كلمتك تقولها وتفرض روحك وقت يلزم ",

    img: require("../assets/results/R3.png"),
  },
  {
    title: "ATTAQUANT",
    text: "ديمة لقدام عمرك لا تغزر لتالي قناص وتعرف تڨمر بلاصتك في 18",

    img: require("../assets/results/R4.png"),
  },

  {
    title: "ENTRAINEUR",
    text: "إنت تكتاك وتغزر لبعيد تعرف إلي يصلح بيك وبالمجموعة",

    img: require("../assets/results/R5.png"),
  },
];
