import { Box, Grid } from '@mui/material';
import React from 'react'
import header from "../assets/header.png";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
export default function Header(props) {

    const icons=[0,1,2,3,4]

  return (
    <div dir="ltr">
      <Grid
        container
        component="main"
        alignItems={"center"}
        justifyContent={"end"}
      >
        <Grid
          item
          xs={12}
          lg={props.isInGame ? 8 : 12}
          sx={{
            ml: { lg: 8, xs: 0 },
            textAlign: { lg: "left", xs: "center" },
          }}
        >
          <a href="/">
            <img src={header} style={{ maxWidth: "300px" }} alt="topnet" />
          </a>
        </Grid>
        {props.isInGame ? (
          <Grid
            item
            justifyContent={"end"}
            xs={12}
            lg={3}
            sx={{
              display: { xs: "none", lg: "block" },
              textAlign: { lg: "left", xs: "center" },
              ml: { lg: 8, xs: 0 },
            }}
          >
            <Box
              sx={{
                backgroundColor: "#fff",
                padding: "20px",
                pl: 5,
                ml: 30,
                borderTopLeftRadius: "35px",
                borderBottomLeftRadius: "35px",
              }}
            >
              {icons.map((i, key) => {
                return (
                  <FiberManualRecordIcon
                    key={key}
                    sx={{
                      fontSize: "medium",
                      color: props.currentIndex < i ? "#D5D5D5" : "#263271",
                    }}
                  />
                );
              })}
            </Box>
          </Grid>
        ) : null}
      </Grid>
    </div>
  );
}
