import {
    SIGNIN_FAILURE,
    SIGNIN_REQUEST,
    SIGNIN_SUCCESS,
    DISCONNECT_THE_USER,
    CONNECT_THE_USER,
    GET_AUTH_REQUEST,
    GET_AUTH_SUCCESS,
    GET_AUTH_FAILURE,
    LOGOUT_REQUEST,
    LOGOUT_FAILURE,
    LOGOUT_SUCCESS,
} from "../actions/auth-actions/types";

const intialState = {
    user: null,
    isLoggedIn: false,
    isLoadingUser: true,
    isSigningIn: false,
    error: null,
    status: null,
    token: null,
};

const authReducer = (state = intialState, action) => {
    switch (action.type) {
        case GET_AUTH_REQUEST:
            return {
                ...state,
                isLoadingUser: true,
            };
        case GET_AUTH_SUCCESS:
            return {
                ...state,
                user: action.payload,
                status: 200,
                isLoadingUser: false,
                isLoggedIn: true,
            };

        case GET_AUTH_FAILURE:
            return {
                ...state,
                isLoadingUser: false,
                status: action.status,
                isLoggedIn: false,
            };

        // Sign in
        case SIGNIN_REQUEST:
            return {
                ...state,
                isSigningIn: true,
                error: null,
            };

        case SIGNIN_SUCCESS:
            return {
                ...state,
                user: action.payload,
                isSigningIn: false,
                isLoggedIn: true,
                error: null,
                token: action.payload.user.accessToken,
            };
        case SIGNIN_FAILURE:
            return {
                ...state,
                isSigningIn: false,
                error: action.payload.error,
            };

        // Logout

        case LOGOUT_REQUEST:
            return state;

        case LOGOUT_SUCCESS:
            return {
                ...state,
                isLoggedIn: false,
                token: null,
            };
        case LOGOUT_FAILURE:
            return state;

        // Connect & disconnect user ( no interaction with the server )
        case DISCONNECT_THE_USER:
            return {
                ...state,
                user: null,
                isLoggedIn: false,
                token: null,
            };

        case CONNECT_THE_USER:
            return {
                ...state,
                isLoggedIn: true,
                token: action.payload.token, // getting token from local storage
            };
        default:
            return state;
    }
};

export default authReducer;
