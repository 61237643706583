import { Grid, Typography, Button, Modal, Box, } from "@mui/material";
import { LazyLoadImage } from "react-lazy-load-image-component";


import React, { useState } from "react";
import side from "../assets/tside.png";
import border from "../assets/border.png";
import Header from "../components/Header";

export default function Landing() {

    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
   
    const styleDesktop = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        bgcolor: 'background.paper',
        border: '2px solid #000',
        borderRadius:"12px",
        boxShadow: 24,
        p: 4,
    };

    return (
      <div>
        <Header />
        <Grid
          container
          component="main"
          alignItems={"center"}
          justifyContent={"end"}
          sx={{
            display: { xs: "flex", lg: "none" },
          }}
        >
          <Grid
            item
            xs={12}
            lg={6}
            md={6}
            sx={{ textAlign: "center", ml: 6, mr: 6 }}
          >
            <Grid
              container
              alignItems={"center"}
              sx={{ textAlign: "center", mt: 4 }}
            >
              <Grid item xs={12}>
                <Typography
                  variant="h2"
                  color="white"
                  noWrap
                  sx={{
                    fontFamily: "LT Bukra Bold",
                  }}
                >
                  فك
                  <br />
                  بلاصتك
                  <br />
                  وإربح
                </Typography>
                <Typography
                  variant="h6"
                  color="white"
                  noWrap
                  sx={{
                    fontFamily: "JF Flat",
                    mt: 2,
                  }}
                >
                  وطلع وين كنت تكور
                  <br />
                  كان جيت ملاعبي،
                  <br />
                  واربح برشة كادوات
                </Typography>
                <Grid container alignItems={"center"} justifyContent={"center"}>
                  <Grid item xs={12}>
                    <Button
                      variant="contained"
                      type="button"
                      fullWidth
                      style={{ fontSize: "25px", borderRadius: "35px" }}
                      onClick={() => (window.location = "/inscription")}
                      sx={{ fontFamily: "LT Bukra Bold", mt: 5 }}
                    >
                      هيا نبداو
                    </Button>
                  </Grid>
                  <Grid item xs={12}>
                    <Button
                      onClick={handleOpen}
                      variant="outlined"
                      type="button"
                      fullWidth
                      sx={{ mt: 2 }}
                      style={{
                        fontSize: "25px",
                        borderRadius: "35px",
                        borderWidth: "5px",
                        fontFamily: "LT Bukra Bold",
                        color: "white",
                      }}
                    >
                      فسرلي قبل
                    </Button>
                    <Modal
                      open={open}
                      onClose={handleClose}
                      aria-labelledby="modal-modal-title"
                      aria-describedby="modal-modal-description"
                    >
                      <Box sx={styleDesktop}>
                        <Typography
                          id="modal-modal-title"
                          variant="h4"
                          component="h2"
                          sx={{
                            textAlign: "center",
                            mb: 5,
                            fontFamily: "LT Bukra Bold",
                          }}
                        >
                          ساهل برشة
                        </Typography>
                        <Typography
                          id="modal-modal-description"
                          sx={{
                            mt: 2,
                            fontFamily: "JF Flat",
                            textAlign: "center",
                          }}
                        >
                          1- إنزل على فلست "هيا نبداو"
                          <br />
                          2- جاوب على الاسئلة الكل كيما يضهرلك
                          <br />
                          3- برطاجي النتيجة إلي جاتك على Compte
                          <br />
                          فيسبوك متاعك بش تدخل في القرعة
                          <br />
                          <br />
                          نشالله مربوحة
                        </Typography>
                      </Box>
                    </Modal>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    lg={9}
                    alignContent={"center"}
                    alignItems={"center"}
                    style={{
                      textAlign: "center",
                      marginTop: "5px",
                    }}
                  >
                    <Button
                      onClick={() =>
                        window.open("https://grandjeutopnet.tn/reglement.pdf")
                      }
                      variant="rounded"
                      type="button"
                      fullWidth
                      style={{
                        fontSize: "15px",
                        borderRadius: "35px",
                        borderWidth: "5px",
                        textAlign: "center",
                        fontFamily: "LT Bukra Bold",
                        color: "white",
                      }}
                    >
                      Règlement du jeu
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid
          container
          component="main"
          alignItems={"center"}
          justifyContent={"start"}
          sx={{
            textAlign: "right",
            display: { xs: "none", lg: "flex" },
          }}
        >
          <Grid
            item
            xs={12}
            lg={6}
            md={6}
            alignItems={"center"}
            justifyContent={"center"}
          >
            <LazyLoadImage
              src={side}
              alt="topnet"
              style={{ width: "80%", textAlign: "right" }}
            />
          </Grid>
          <Grid item xs={12} lg={6} md={6} sx={{ textAlign: "right" }}>
            <Grid container alignItems={"start"} sx={{ textAlign: "right" }}>
              <Grid item xs={1} lg={2}>
                <LazyLoadImage
                  src={border}
                  style={{ width: "100%" }}
                  alt="topnet"
                />
              </Grid>

              <Grid item xs={11} lg={9} sx={{ ml: 5 }}>
                <Typography
                  variant="h1"
                  color="white"
                  noWrap
                  sx={{
                    fontFamily: "LT Bukra Bold",
                  }}
                >
                  فك بلاصتك
                  <br />
                  وإربح
                </Typography>
                <Typography
                  variant="h5"
                  color="white"
                  noWrap
                  sx={{
                    fontFamily: "JF Flat",
                    mt: 5,
                  }}
                >
                  وطلع وين كنت تكور كان جيت ملاعبي،
                  <br />
                  واربح برشة كادوات
                </Typography>
                <Grid
                  container
                  alignItems={"start"}
                  justifyContent={"start"}
                  sx={{ textAlign: "right", mt: 5 }}
                >
                  <Grid item xs={12} lg={4} sx={{ ml: 5 }}>
                    <Button
                      variant="contained"
                      type="button"
                      fullWidth
                      style={{ fontSize: "25px", borderRadius: "35px" }}
                      sx={{ fontFamily: "LT Bukra Bold" }}
                      onClick={() => (window.location = "/inscription")}
                    >
                      هيا نبداو
                    </Button>
                  </Grid>
                  <Grid item xs={12} lg={4}>
                    <Button
                      onClick={handleOpen}
                      variant="outlined"
                      type="button"
                      fullWidth
                      style={{
                        fontSize: "25px",
                        borderRadius: "35px",
                        borderWidth: "5px",
                        fontFamily: "LT Bukra Bold",
                        color: "white",
                      }}
                    >
                      فسرلي قبل
                    </Button>
                    <Modal
                      open={open}
                      onClose={handleClose}
                      aria-labelledby="modal-modal-title"
                      aria-describedby="modal-modal-description"
                    >
                      <Box sx={styleDesktop}>
                        <Typography
                          id="modal-modal-title"
                          variant="h4"
                          component="h2"
                          sx={{
                            textAlign: "center",
                            mb: 5,
                            fontFamily: "LT Bukra Bold",
                          }}
                        >
                          ساهل برشة
                        </Typography>
                        <Typography
                          id="modal-modal-description"
                          sx={{
                            mt: 2,
                            fontFamily: "JF Flat",
                            textAlign: "center",
                          }}
                        >
                          1- إنزل على فلست "هيا نبداو"
                          <br />
                          2- جواب على الاسئلة الكل كيما يضهرلك
                          <br />
                          3- برطاجي النتيجة إلي جاتك على Compte
                          <br />
                          فيسبوك متاعك بش تدخل في القرعة
                          <br />
                          <br />
                          نشالله مربوحة
                        </Typography>
                      </Box>
                    </Modal>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    lg={3}
                    alignContent={"center"}
                    alignItems={"center"}
                    style={{
                      textAlign: "center",
                    }}
                  ></Grid>
                  <Grid
                    item
                    xs={12}
                    lg={9}
                    alignContent={"center"}
                    alignItems={"center"}
                    style={{
                      textAlign: "center",
                      marginTop: "20px",
                    }}
                  >
                    <Button
                      onClick={() =>
                        window.open("https://grandjeutopnet.tn/reglement.pdf")
                      }
                      variant="rounded"
                      type="button"
                      fullWidth
                      style={{
                        fontSize: "15px",
                        borderRadius: "35px",
                        borderWidth: "5px",
                        textAlign: "center",
                        fontFamily: "LT Bukra Bold",
                        color: "white",
                      }}
                    >
                      Règlement du jeu
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </div>
    );
}
