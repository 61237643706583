import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import store from "./stores/store-dev";
import { ThemeProvider } from '@emotion/react';
import { createTheme } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import './fonts.css';

import { Provider } from "react-redux";

import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom';
import ReactAudioPlayer from 'react-audio-player';
import track from './assets/music/track.mp3'
const theme = createTheme({

  
  palette: {
    primary: {
      main: '#E32E7B',
  
    },
   
  },
});
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <ThemeProvider theme={theme}>
        <CssBaseline />

        <Provider store={store}>
          <ReactAudioPlayer src={track} autoPlay onPlay={(e) => console.log(e)}
          volume={.3}
          loop
          />
          <App />
        </Provider>
      </ThemeProvider>
    </BrowserRouter>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
