import { Box, Button, Grid, Typography } from "@mui/material";

import React, { useState, useEffect } from "react";
import { questions, results } from "./data";
import { LazyLoadImage } from "react-lazy-load-image-component";
import Header from "../components/Header";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import { doc, getDoc, serverTimestamp, updateDoc } from "firebase/firestore";
import { firestore } from "../firebaseConfig";

export default function Game() {
  const [currentQuestion, setCurrentQuestion] = useState(0);
  const [answers, setAnswers] = useState([]);
  const [gameOver, setGameOver] = useState(false);
  const [resultIndex, setResultIndex] = useState(0);
  const urls = [
    "/result1.html",
    "/result2.html",
    "/result3.html",
    "/result4.html",
    "/result5.html",
  ];
  const addScore = async (score) => {
    try {
      await updateDoc(doc(firestore, "users", localStorage.getItem("uid")), {
        ...score,

        updatedAt: serverTimestamp(),
      });
    } catch (error) {
      console.error("Error adding document: ", error);
    }
  };
  const handleAnswer = (newAnswer) => {
    if (currentQuestion < 4) {
      const ans = answers;
      ans.push(newAnswer);
      setAnswers(ans);
      console.log(ans);
      setCurrentQuestion(currentQuestion + 1);
    } else {
      const randomNumber = Math.floor(Math.random() * 4 + 1);
      setResultIndex(randomNumber);
      addScore({ result: results[randomNumber].title });
      setGameOver(true);
    }
  };
  useEffect(() => {
    if (
      localStorage.getItem("uid") == null ||
      localStorage.getItem("uid")?.length === 0
    ) {
      window.location = "/";
    } else {
      getDoc(doc(firestore, "users", localStorage.getItem("uid"))).then(
        (docSnapshot) => {
          if (!docSnapshot.data()) {
            window.location = "/";
          }
        }
      );
    }
  }, []);
  useEffect(() => {
    const preloadImages = () => {
      const images = [...questions, ...results].reduce((acc, item) => {
        if (item.img) {
          acc.push(item.img);
        }
        if (item.answers) {
          acc.push(...item.answers);
        }
        return acc;
      }, []);

      images.forEach((src) => {
        const img = new Image();
        img.src = src;
      });
    };

    preloadImages();
  }, []);
  const icons = [0, 1, 2, 3, 4];

  return (
    <div>
      <Header isInGame={true} currentIndex={currentQuestion} />
      {!gameOver ? (
        <div>
          <Grid
            container
            component="main"
            alignItems={"center"}
            justifyContent={"center"}
            sx={{
              mt: { xs: 5, lg: 2 },
              display: { xs: "flex", lg: "none" },
            }}
          >
            <Grid
              container
              component="main"
              alignItems={"center"}
              justifyContent={"center"}
              sx={{
                mt: 1,
              }}
            >
              {questions[currentQuestion].answers.map((img, key) => {
                return (
                  <Grid item xs={6} key={key}>
                    <Button
                      sx={{
                        "&:before": {
                          backgroundColor: "#f00!important",
                          background: "#f00!important",
                        },
                      }}
                      style={{ width: "70%" }}
                      onClick={() =>
                        handleAnswer(
                          questions[currentQuestion].answers.indexOf(img)
                        )
                      }
                    >
                      <LazyLoadImage
                        src={img}
                        key={key}
                        alt={key}
                        style={{ width: "100%" }}
                      />
                    </Button>
                  </Grid>
                );
              })}
            </Grid>
            <Grid
              container
              component="main"
              alignItems={"center"}
              justifyContent={"center"}
              sx={{
                mt: 5,
                pt: 2,
                pb: 2,
                backgroundColor: "#fff",
                borderImage:
                  "linear-gradient(to left bottom, #FF8300, #12B6FD) ",
                border: "solid 6px",
                borderImageSlice: "1",
                borderRadius: "50px",
              }}
            >
              <Typography
                variant="h6"
                color="#514E46"
                sx={{
                  fontFamily: "LT Bukra Bold",
                }}
              >
                {questions[currentQuestion].question}
              </Typography>
            </Grid>
          </Grid>
          <Grid
            container
            component="main"
            alignItems={"center"}
            justifyContent={"center"}
            sx={{
              display: { xs: "none", lg: "flex" },
            }}
          >
            <Grid
              container
              component="main"
              alignItems={"center"}
              justifyContent={"center"}
              sx={{
                mt: 10,
                ml: 40,
                mr: 40,
                display: { xs: "none", lg: "flex" },
              }}
            >
              {questions[currentQuestion].answers.map((img, key) => {
                return (
                  <Grid item xs={3} lg={3} md={3} key={key}>
                    <Button
                      style={{ width: "100%" }}
                      onClick={() =>
                        handleAnswer(
                          questions[currentQuestion].answers.indexOf(img)
                        )
                      }
                    >
                      <LazyLoadImage
                        src={img}
                        alt={key}
                        style={{ width: "100%" }}
                      />
                    </Button>
                  </Grid>
                );
              })}
            </Grid>
            <Grid
              container
              component="main"
              alignItems={"center"}
              justifyContent={"center"}
              sx={{
                mt: 5,
                ml: 40,
                mr: 40,
                pt: 3,
                pb: 3,
                backgroundColor: "#fff",
                borderColor:
                  "linear-gradient(to left bottom, #FF8300, #12B6FD) ",
                border: "solid 6px",
                borderImageSlice: "1",
                borderRadius: "50px",
                display: { xs: "none", lg: "flex" },
              }}
            >
              <Typography
                variant="h3"
                color="#514E46"
                sx={{
                  fontFamily: "LT Bukra Bold",
                }}
              >
                {questions[currentQuestion].question}
              </Typography>
            </Grid>
          </Grid>
          <div dir="ltr">
            <Grid
              item
              justifyContent={"center"}
              xs={6}
              sx={{
                display: { xs: "block", lg: "none" },
                textAlign: { lg: "left", xs: "center" },
              }}
            >
              <Box
                sx={{
                  backgroundColor: "#fff",
                  padding: "10px",
                  pt: 2,

                  ml: 16,
                  mt: 1,
                  mr: 16,
                  borderRadius: "35px",
                }}
              >
                {icons.map((i, key) => {
                  return (
                    <FiberManualRecordIcon
                      key={key}
                      sx={{
                        fontSize: "medium",
                        color: currentQuestion < i ? "#D5D5D5" : "#263271",
                      }}
                    />
                  );
                })}
              </Box>
            </Grid>
          </div>
        </div>
      ) : (
        <div>
          <Grid
            container
            component="main"
            alignItems={"center"}
            sx={{
              display: { xs: "flex", lg: "none" },
            }}
          >
            <Grid
              item
              xs={12}
              sx={{
                mt: 5,
              }}
            >
              <LazyLoadImage
                src={results[resultIndex].img}
                alt={"topnet"}
                style={{ width: "50%" }}
              />
            </Grid>
            <Grid item xs={12} sx={{ textAlign: "center" }}>
              <Grid item xs={12}>
                <Typography
                  variant="h4"
                  color="white"
                  sx={{
                    fontFamily: "LT Bukra Bold",
                    mt: 5,
                  }}
                >
                  {results[resultIndex].title}
                </Typography>
                <Typography
                  variant="h6"
                  color="white"
                  sx={{
                    fontFamily: "JF Flat",
                    mt: 2,
                  }}
                >
                  {results[resultIndex].text}
                </Typography>
                <Grid
                  container
                  alignItems={"start"}
                  justifyContent={"start"}
                  sx={{ textAlign: "right", mt: 5 }}
                >
                  <Grid item xs={11}>
                    <Button
                      onClick={() => {
                        window.open(
                          `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(
                            "https://grandjeutopnet.tn/" + urls[resultIndex]
                          )}`,
                          "_blank"
                        );
                      }}
                      variant="contained"
                      type="button"
                      fullWidth
                      style={{ fontSize: "15px", borderRadius: "35px" }}
                      sx={{ fontFamily: "LT Bukra Bold", ml: 2, mr: 2 }}
                    >
                      برتاجي وكونفرمي مشاركتك{" "}
                    </Button>
                  </Grid>
                  <Grid item xs={11} sx={{ mt: 2 }}>
                    <Button
                      onClick={() => {
                        window.location = "/game";
                      }}
                      variant="outlined"
                      type="button"
                      fullWidth
                      style={{
                        fontSize: "15px",
                        borderRadius: "35px",
                        borderWidth: "5px",
                      }}
                      sx={{ fontFamily: "LT Bukra Bold", ml: 2, mr: 2 }}
                    >
                      عاود ألعب{" "}
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid
            container
            component="main"
            alignItems={"center"}
            sx={{
              display: { xs: "none", lg: "flex" },
            }}
          >
            <Grid item lg={6} md={6}>
              <LazyLoadImage
                src={results[resultIndex].img}
                alt={"topnet"}
                style={{ width: "50%" }}
              />
            </Grid>
            <Grid item lg={6} md={6} sx={{ textAlign: "right" }}>
              <Grid item xs={11} lg={9} sx={{ ml: 5 }}>
                <Typography
                  variant="h1"
                  color="white"
                  sx={{
                    fontFamily: "LT Bukra Bold",
                  }}
                >
                  {results[resultIndex].title}
                </Typography>
                <Typography
                  variant="h5"
                  color="white"
                  sx={{
                    fontFamily: "JF Flat",
                    mt: 5,
                  }}
                >
                  {results[resultIndex].text}
                </Typography>
                <Grid
                  container
                  alignItems={"start"}
                  justifyContent={"start"}
                  sx={{ textAlign: "right", mt: 10 }}
                >
                  <Grid item xs={12} lg={10} sx={{ ml: 5 }}>
                    <Button
                      onClick={() => {
                        window.open(
                          `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(
                            "https://grandjeutopnet.tn/" + urls[resultIndex]
                          )}`,
                          "_blank"
                        );
                      }}
                      variant="contained"
                      type="button"
                      fullWidth
                      style={{ fontSize: "23px", borderRadius: "35px" }}
                      sx={{ fontFamily: "LT Bukra Bold" }}
                    >
                      برتاجي وكونفرمي مشاركتك{" "}
                    </Button>
                  </Grid>
                  <Grid item xs={12} lg={10} sx={{ ml: 5, mt: 5 }}>
                    <Button
                      onClick={() => {
                        window.location = "/game";
                      }}
                      variant="outlined"
                      type="button"
                      fullWidth
                      style={{
                        fontSize: "23px",
                        borderRadius: "35px",
                        borderWidth: "5px",
                      }}
                      sx={{ fontFamily: "LT Bukra Bold" }}
                    >
                      عاود ألعب{" "}
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </div>
      )}
    </div>
  );
}
