import './App.css';
import Landing from './pages/Landing';
import Game from './pages/Game';
import { Routes, Route } from "react-router-dom";
import Register from './pages/Register';
import { HelmetProvider } from "react-helmet-async";
import AudioPlayer from "react-audio-player";

function App() {
  return (
    <div className="App">
      <HelmetProvider>
        <Routes>
          <Route path="/" element={<Landing />} />
          <Route path="/inscription" element={<Register />} />
          <Route path="/game" element={<Game />} />
        </Routes>
      </HelmetProvider>
    </div>
  );
}

export default App;
