import { initializeApp } from "firebase/app";
import { getStorage } from "firebase/storage";
import { getAuth, signInAnonymously } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getAnalytics } from "firebase/analytics";


// Initialize Firebase
const firebaseConfig = {
    apiKey: process.env.REACT_APP_API_KEY,
    authDomain: process.env.REACT_APP_AUTH_DOMAIN,
    projectId: process.env.REACT_APP_PROJECT_ID,
    storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_APP_ID,
    measurementId: process.env.REACT_APP_MEASUREMENT_ID,
};

const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

// Firebase storage reference
export const auth = getAuth(app);
auth.onAuthStateChanged(async (user) => {
     if (user) {
       // User is signed in, see docs for a list of available properties
       // https://firebase.google.com/docs/reference/js/firebase.User
       const uid = user.uid;
       localStorage.setItem("uid",uid)
       // ...
     } else {
       signInAnonymously(auth)
         .then(() => {
           // Signed in..
         })
         .catch((error) => {
           const errorCode = error.code;
           const errorMessage = error.message;
           // ...
         });
       // User is signed out
       // ...
     }
});
export const firestore = getFirestore(app);


export const storage = getStorage(app);
export default app;
