import {
  Typography,
  Button,
  Box,
  TextField,
  Container,
  Checkbox,
  FormControlLabel,
  FormControl,
  MenuItem,
  Select,
  InputLabel,
} from "@mui/material";

import React, { useEffect, useState } from "react";

import Header from "../components/Header";
import { doc, getDoc, serverTimestamp, setDoc } from "firebase/firestore";
import { firestore } from "../firebaseConfig";
export default function Register() {
  useEffect(() => {
    if (
      localStorage.getItem("uid") == null ||
      localStorage.getItem("uid")?.length === 0
    ) {
      window.location = "/";
    } else {
      getDoc(doc(firestore, "users", localStorage.getItem("uid"))).then(
        (docSnapshot) => {
          if (docSnapshot.data()) {
            window.location = "/game";
          } else {
            setLoadForm(true);
          }
        }
      );
    }
  }, []);
  const [data, setData] = useState({
    firstName: "",
    lastName: "",
    phone: 0,
    email: "",
  });
  const [loadForm, setLoadForm] = useState(false);
  const [firstNameValid, setFirstNameValid] = useState(null);
  const [lastNameValid, setLastNameValid] = useState(null);
  const [emailValid, setEmailValid] = useState(null);
  const [phoneValid, setPhoneValid] = useState(null);
  const [debit, setDebit] = useState(null);
  const handleFirstNameChange = (event) => {
    const newData = data;
    newData.firstName = event.target.value;
    setData(newData);

    if (event.target.value.length <= 0) {
      setFirstNameValid(true);
    } else {
      setFirstNameValid(false);
    }
  };
  const handleDebitChange = (event) => {
    console.log(event.target.value)
    const newData = data;
    newData.debit = event.target.value;
    setDebit(event.target.value);
    setData(newData);
  };
  const handleLastNameChange = (event) => {
    const newData = data;
    newData.lastName = event.target.value;
    setData(newData);

    if (event.target.value.length <= 0) {
      setLastNameValid(true);
    } else {
      setLastNameValid(false);
    }
  };
  const handlePhoneChange = (event) => {
    const newData = data;
    newData.phone = event.target.value;
    setData(newData);

    if (event.target.value.length <= 7) {
      setPhoneValid(true);
    } else {
      setPhoneValid(false);
    }
  };
  const handleEmailChange = (event) => {
    const newData = data;
    newData.email = event.target.value;
    setData(newData);

    const emailRegex =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (emailRegex.test(event.target.value)) {
      setEmailValid(false);
    } else {
      setEmailValid(true);
    }
  };
  const addUserRequest = async (user) => {
    try {
      await setDoc(doc(firestore, "users", localStorage.getItem("uid")), {
        ...user,
        createdAt: serverTimestamp(),
        updatedAt: serverTimestamp(),
      });

      window.location = "/game";
    } catch (error) {
      console.error("Error adding document: ", error);
    }
  };
  const handleSubmit = async (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    if (
      lastNameValid === false &&
      firstNameValid === false &&
      phoneValid === false &&
      emailValid === false
    ) {
      const user = {
        firstName: data.get("firstName"),
        lastName: data.get("lastName"),
        phone: data.get("phone"),
        email: data.get("email"),
        internet: debit,
        subscribed: data.get("subscribed"),
      };
console.log(user)
      await addUserRequest(user);
    } else {
      if (lastNameValid == null) {
        setLastNameValid(true);
      }
      if (firstNameValid == null) {
        setFirstNameValid(true);
      }
      if (phoneValid == null) {
        setPhoneValid(true);
      }
      if (emailValid == null) {
        setEmailValid(true);
      }
    }
  };

  return (
    <div dir="ltr">
      <Header />
      {loadForm ? (
        <Container
          component="main"
          maxWidth="sm"
          alignItems={"center"}
          justifyContent={"center"}
        >
          <Box
            component="form"
            onSubmit={handleSubmit}
            noValidate
            sx={{ mt: 1 }}
          >
            <Typography
              variant="h2"
              color="white"
              sx={{
                fontSize: {
                  xs: "45px",
                  lg: "64px",
                },
                fontFamily: "LT Bukra Bold",
                mt: 5,
                mb: 5,
              }}
            >
              تك تك وننطلقوا
            </Typography>

            <TextField
              variant="outlined"
              error={firstNameValid}
              helperText={firstNameValid ? "Ce champ est obligatoire." : ""}
              onChange={(e) => handleFirstNameChange(e, "firstName")}
              inputProps={{
                style: {
                  textAlign: "left",
                  borderRadius: "35px",
                  textTransform: "uppercase",
                  backgroundColor: "white",
                  fontFamily: "LT Bukra Bold",
                },
              }}
              InputProps={{
                style: {
                  textAlign: "left",
                  borderRadius: "35px",
                  textTransform: "uppercase",
                  backgroundColor: "white",
                  fontFamily: "LT Bukra Bold",
                },
              }}
              InputLabelProps={{
                style: {
                  color: "#D5D5D5",
                  borderRadius: "35px !important",
                  fontFamily: "LT Bukra Bold",
                  textTransform: "uppercase",
                },
              }}
              margin="normal"
              required
              fullWidth
              id="firstName"
              label="Nom"
              name="firstName"
              autoComplete="family-name"
            />
            <TextField
              variant="outlined"
              error={lastNameValid}
              helperText={lastNameValid ? "Ce champ est obligatoire." : ""}
              onChange={(e) => handleLastNameChange(e)}
              inputProps={{
                style: {
                  textAlign: "left",
                  borderRadius: "35px",
                  textTransform: "uppercase",
                  backgroundColor: "white",
                  fontFamily: "LT Bukra Bold",
                },
              }}
              InputProps={{
                style: {
                  textAlign: "left",
                  borderRadius: "35px",
                  textTransform: "uppercase",
                  backgroundColor: "white",
                  fontFamily: "LT Bukra Bold",
                },
              }}
              InputLabelProps={{
                style: {
                  textTransform: "uppercase",
                  color: "#D5D5D5",
                  borderRadius: "35px !important",
                  fontFamily: "LT Bukra Bold",
                },
              }}
              margin="normal"
              required
              fullWidth
              id="lastName"
              label="Prénom"
              name="lastName"
              autoComplete="given-name"
            />
            <TextField
              variant="outlined"
              onKeyDown={(evt) =>
                (evt.key === "e" || evt.key === "+" || evt.key === "-") &&
                evt.preventDefault()
              }
              error={phoneValid}
              helperText={phoneValid ? "Numéro de téléphone invalide." : ""}
              onChange={(e) => handlePhoneChange(e)}
              inputProps={{
                type: "number",
                inputMode: "numeric", // Assure la saisie de caractères numériques
                pattern: "[0-9]*",

                style: {
                  textAlign: "left",
                  borderRadius: "35px",
                  textTransform: "uppercase",
                  backgroundColor: "white",
                  fontFamily: "LT Bukra Bold",
                },
              }}
              InputProps={{
                style: {
                  textAlign: "left",
                  borderRadius: "35px",
                  textTransform: "uppercase",
                  backgroundColor: "white",
                  fontFamily: "LT Bukra Bold",
                },
              }}
              InputLabelProps={{
                style: {
                  textTransform: "uppercase",
                  color: "#D5D5D5",
                  borderRadius: "35px !important",
                  fontFamily: "LT Bukra Bold",
                },
              }}
              margin="normal"
              required
              fullWidth
              id="phone"
              label="Numéro de téléphone"
              name="phone"
              type="number"
              autoComplete="phone"
            />
            <TextField
              variant="outlined"
              error={emailValid}
              helperText={emailValid ? "Email invalide." : ""}
              onChange={(e) => handleEmailChange(e)}
              inputProps={{
                style: {
                  textAlign: "left",
                  borderRadius: "35px",
                  textTransform: "uppercase",
                  backgroundColor: "white",
                  fontFamily: "LT Bukra Bold",
                },
              }}
              InputProps={{
                style: {
                  textAlign: "left",
                  borderRadius: "35px",
                  textTransform: "uppercase",
                  backgroundColor: "white",
                  fontFamily: "LT Bukra Bold",
                },
              }}
              InputLabelProps={{
                style: {
                  textTransform: "uppercase",
                  color: "#D5D5D5",
                  borderRadius: "35px !important",
                  fontFamily: "LT Bukra Bold",
                },
              }}
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email"
              name="email"
              autoComplete="email"
            />
            <FormControl
              fullWidth
              dir="ltr"
              style={{ marginTop: "18px", marginBottom: "18px" }}
            >
              <InputLabel
                id="demo-simple-select-label"
                style={{
                  textAlign: "left",
                  borderRadius: "35px",
                  textTransform: "uppercase",
                  backgroundColor: "white",
                  color: "#D5D5D5",
                  fontFamily: "LT Bukra Bold",
                  margin: "normal",
                }}
              >
                Votre débit Internet
              </InputLabel>
              <Select
                value={debit}
                style={{
                  textAlign: "left",
                  borderRadius: "35px",
                  textTransform: "uppercase",
                  backgroundColor: "white",
                  fontFamily: "LT Bukra Bold",
                  margin: "normal",
                }}
                inputProps={{
                  style: {
                    textAlign: "left",
                    borderRadius: "35px",
                    textTransform: "uppercase",
                    backgroundColor: "white",
                    fontFamily: "LT Bukra Bold",
                  },
                }}
                InputProps={{
                  style: {
                    textAlign: "left",
                    borderRadius: "35px",
                    textTransform: "uppercase",
                    backgroundColor: "white",
                    fontFamily: "LT Bukra Bold",
                  },
                }}
                InputLabelProps={{
                  style: {
                    textTransform: "uppercase",
                    color: "#D5D5D5",
                    borderRadius: "35px !important",
                    fontFamily: "LT Bukra Bold",
                  },
                }}
                labelId="debit"
                id="debit"
                label="Votre débit Internet"
                onChange={handleDebitChange}
              >
                <MenuItem
                  value={10}
                  style={{ textAlign: "center", fontFamily: "LT Bukra Bold" }}
                >
                  10 Méga
                </MenuItem>
                <MenuItem value={12} style={{ fontFamily: "LT Bukra Bold" }}>
                  12 Méga
                </MenuItem>
                <MenuItem value={20} style={{ fontFamily: "LT Bukra Bold" }}>
                  20 Méga
                </MenuItem>
                <MenuItem value={30} style={{ fontFamily: "LT Bukra Bold" }}>
                  30 Méga
                </MenuItem>

                <MenuItem value={50} style={{ fontFamily: "LT Bukra Bold" }}>
                  50 Méga
                </MenuItem>
                <MenuItem value={100} style={{ fontFamily: "LT Bukra Bold" }}>
                  100 Méga
                </MenuItem>
              </Select>
            </FormControl>
            <FormControlLabel
              control={
                <Checkbox
                  id={"subscribed"}
                  name={"subscribed"}
                  InputProps={{
                    style: {
                      textAlign: "left",
                      borderRadius: "35px",
                      textTransform: "uppercase",
                      backgroundColor: "white",
                      fontFamily: "LT Bukra Bold",
                    },
                  }}
                />
              }
              style={{
                fontFamily: "LT Bukra Bold",
                textAlign: "left",

                color: "white",
              }}
              dir="rtl"
              label={
                <Typography
                  variant="h6"
                  color="#fff"
                  sx={{
                    fontFamily: "LT Bukra Bold",
                  }}
                >
                  أنت TOPNET abonné(e)؟
                </Typography>
              }
            />
            <Button
              variant="contained"
              type="submit"
              fullWidth
              style={{ fontSize: "25px", borderRadius: "35px" }}
              sx={{ fontFamily: "LT Bukra Bold", mt: 5 }}
            >
              هيا نبداو برسمي
            </Button>
          </Box>
        </Container>
      ) : null}
    </div>
  );
}
